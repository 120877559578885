import React from 'react';
import './Samples.scss';
import '../../global.css';

function Samples() {
  return (
    <div className='samples-container'>
      <h1 className='samples-header'>Check out some of my mixes and beats!</h1>
      <div className="soundcloud-container">
        <iframe className='sample-item' title="SoundCloud Player" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1481552014&color=%23ff5500&inverse=false&auto_play=true&show_user=true" width="100%" height="166" scrolling="no" frameborder="no"></iframe>
        <iframe className='sample-item' title="SoundCloud Player" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/701697133&color=%23ff5500&inverse=false&auto_play=false&show_user=true" width="100%" height="166" scrolling="no" frameborder="no"></iframe>
      </div>
    </div>
  );
}

export default Samples;

