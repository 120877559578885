import React from 'react';
import './About.scss';
import '../../global.css';

function About() {
  return (
    <div className="about-container">
      <div className="about-text">
      <h1 className="about-title">About RMCXC Music</h1>
      <p className="about-paragraph">RMCXC Music is a professional DJ service that provides music for all types of events, including weddings, parties, and corporate events. We will work with you to create a wonderful musical experience for your event.</p>
      </div>
      <img className="about-image" src={process.env.PUBLIC_URL + '/rmcxc.jpg'} alt="DJ" />
    </div>
  );
}

export default About;