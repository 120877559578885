import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import './Contact.scss';
import '../../global.css';
import axios from 'axios';

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/contact", {
        name: name,
        email: email,
        message: message,
      })
      .then((res) => {
        console.log(res);
        setName('');
        setEmail('');
        setMessage('');
        setSuccessMessage('Form submitted successfully!');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container">
      <h1 className='form__header'>Contact Us</h1>
      <Form onSubmit={handleSubmit}>
        
        <Form.Group controlId="formBasicName">
          <Form.Label className='form__label'>Name</Form.Label>
          <Form.Control
            className='form__input'
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label className='form__label'>Email</Form.Label>
          <Form.Control
            className='form__input'
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicMessage">
          <Form.Label className='form__label'>Message</Form.Label>
          <Form.Control
            className='form__textarea'
            as="textarea"
            rows={5}
            placeholder="Enter your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Form.Group>

        <Button className='form__button' variant="primary" type="submit">
          Submit
        </Button>
        {successMessage && <p>{successMessage}</p>}
      </Form>
    </div>
  );
};

export default Contact;
