import React from 'react';
import './Gallery.scss';

function Gallery() {
  return (
    <div className="gallery">
      <h1 className="gallery-header">Check Out Our Gallery</h1>
      <div className="gallery-image">
        <img src={process.env.PUBLIC_URL + '/rmcxc.jpg'} alt="Gallery 1" />
      </div>
      
    </div>
  );
}

export default Gallery;