import Header from './components/Header/Header';
import Home from './components/Home/Home';
import About from './components/About/About';
import Samples from './components/Samples/Samples';
import Gallery from './components/Gallery/Gallery';
import Contact from './components/Contact/Contact';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {
  return (
    <Router>
      <div className="App">
        <Header/>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/samples" element={<Samples />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
      </div>
    </Router>
  );
}

export default App;
