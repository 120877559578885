import React from 'react';
import './Home.scss';
import '../../global.css';

import { Link } from 'react-router-dom';


function Home() {
  return (
    <div className="home-container">
      <h1>Welcome to Rocketman's Boogieland!</h1>
      <p>Check out some of my mixes and beats:</p>
      <Link to="/samples">
        <button className="btn">Samples</button>
      </Link>
    </div>
  );
}

export default Home;